'use client';

import './QuestCard.css';

import React, { useState } from 'react';
import { useTranslations } from 'next-intl';
import Link from 'next/link';

import { TaskBadges } from '#components/TaskBadge/TaskBadges';
import { cn } from '#utils/utils';

import type { QuestCardProps } from './QuestCard.types';
import { QuestStatus } from './components/QuestStatus';
import { getQuestStatus, QuestStatusReason } from './components/QuestStatus/QuestStatus.utils';
import { QuestUnlockInfo } from './components/QuestUnlockInfo';
import { Recurrence } from './components/RecurrenceBadge';
import { RewardStack } from './components/RewardStack/RewardStack';
import { QuestCardSkeleton } from './QuestCard.skeleton';
import { container, info, title } from './QuestCard.styles';

export const QuestCard = ({
  as: Component = Link,
  quest,
  className,
  onClick,
  isSubscriptionLimitReached,
  ...rest
}: QuestCardProps) => {
  const [shouldShowHowToUnlock, setShouldShowHowToUnlock] = useState(false);
  const t = useTranslations('quest');

  const toggleHowToUnlock = () => setShouldShowHowToUnlock(prev => !prev);
  const hideHowToUnlock = () => setShouldShowHowToUnlock(false);

  const questStatus = getQuestStatus(quest, !!isSubscriptionLimitReached);

  const isDisabled =
    !questStatus.canClaim && questStatus.reason !== QuestStatusReason.CLAIM_REWARDS;

  const onClickWithoutPropagation = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (quest.locked && quest.conditions.length > 0) {
      toggleHowToUnlock();
    }

    if (questStatus.disabled) {
      return e.preventDefault();
    }

    onClick?.(e);
  };

  return (
    <QuestUnlockInfo
      onHide={hideHowToUnlock}
      matchers={quest.conditions}
      open={shouldShowHowToUnlock}
      operator={quest.conditionOperator}
    >
      <Component
        aria-disabled={isDisabled}
        data-disabled={isDisabled}
        className={cn(container, 'relative', isDisabled ? '' : 'quest-card-gradient', className)}
        {...rest}
        onClick={onClickWithoutPropagation}
        id={quest.id}
      >
        <div className={info}>
          {
            <span
              className={cn(title, {
                'text-tertiary': isDisabled || !quest.name,
              })}
            >
              {quest.name || t('placeholder')}
            </span>
          }
          <div className="flex flex-row gap-150 min-w-0">
            <TaskBadges
              disabled={isDisabled}
              size="xs"
              badges={quest.tasks.map(({ type }) => type)}
              limit={2}
            />
            <Recurrence {...quest} />
          </div>
        </div>
        <RewardStack quest={quest} />
        <div className="gradient-border w-full h-full absolute inset-0 after:transition-colors" />
        <div className="absolute top-[calc(100%-16px)] left-[50%] origin-top translate-x-[-50%]">
          <QuestStatus {...quest} />
        </div>
      </Component>
    </QuestUnlockInfo>
  );
};

QuestCard.Skeleton = QuestCardSkeleton;
