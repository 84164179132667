import { cx } from 'class-variance-authority';

export const container = cx([
  // group helper. Resets stacking context by using z-0
  'group/quest-card z-0 min-w-0 w-full cursor-pointer',
  // component base
  'rounded-quest-card-questcard',
  // generic tokens
  'relative flex flex-row outline-none transition-colors duration-200',
  // disabled states
  'data-[disabled=true]:cursor-not-allowed',
  // combined states
  'data-[clickable=true]:focus-visible:ring-quest-card-focus',
]);

export const info =
  'py-quest-card-xxl-y px-quest-card-md-left flex flex-col flex-1 gap-100 min-w-0';

export const title =
  'text-quest-card-primary quest-card-quest-name self-baseline text-start line-clamp-2';
