'use client';

import { Skeleton } from '@zealy/design-system';

import { ModuleCard } from '#app/cw/[subdomain]/(app)/questboard/_components/GridView/ModuleCard';
import { QuestCard } from '#components/QuestCard';

type SkeletonProps = {
  totalOfSkeletons?: number;
};

const ModuleList = ({ totalOfSkeletons = 1 }: SkeletonProps) => {
  return (
    <div className="justify-center flex flex-col xl:flex-row flex-wrap gap-600 xl:gap-1000 mx-auto py-600 max-w-[942px] w-full">
      <div className="flex flex-1 flex-col gap-600 xl:gap-1000 xl:max-w-[428px] w-full min-w-0">
        {new Array(totalOfSkeletons).fill('').map((_, index) => (
          <ModuleCard.Skeleton key={`module-list-skeleton-${index}`} />
        ))}
      </div>
      <div className="flex flex-1 flex-col gap-600 xl:gap-1000 pt-1000 xl:max-w-[428px]">
        {new Array(totalOfSkeletons).fill('').map((_, index) => (
          <ModuleCard.Skeleton key={`module-list-skeleton-${index}`} />
        ))}
      </div>
    </div>
  );
};

const ModuleHeader = () => (
  <div className="grid lg:grid-cols-2 py-400 rounded-t-md px-600 xl:px-1200 bg-no-repeat bg-center bg-cover bg-[var(--cover)] items-end">
    <div className="flex flex-col gap-150">
      <Skeleton className="w-[200px] h-300" />

      <div className="flex flex-col gap-100">
        <Skeleton className="w-full h-150 mb-200" />
        <Skeleton className="w-[50px] h-150" />
        <div className="flex flex-row gap-100">
          <Skeleton className="w-full h-100" />
          <Skeleton className="w-[20px] h-100" />
        </div>
      </div>
    </div>
  </div>
);

const ModuleSection = ({ totalOfSkeletons = 6 }: SkeletonProps) => {
  return (
    <div className="rounded-t-md">
      <ModuleHeader />

      <div className="rounded-t-md bg-secondary grid lg:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 gap-400 py-400 w-full px-200 md:px-600 xl:px-1200 md:py-800">
        {new Array(totalOfSkeletons).fill('').map((_, index) => (
          <QuestCard.Skeleton key={`quest-list-skeleton-${index}`} />
        ))}
      </div>
    </div>
  );
};

const SectionView = ({ totalOfSkeletons = 1 }: SkeletonProps) => (
  <>
    {new Array(totalOfSkeletons).fill('').map((_, index) => (
      <ModuleSection key={`module-section-skeleton-${index}`} />
    ))}
  </>
);

const QuestList = ({ totalOfSkeletons = 1 }: SkeletonProps) => {
  return (
    <div className="flex flex-col p-200 gap-300 overflow-hidden w-full">
      <div className="flex flex-col gap-300">
        <Skeleton className="w-button-md h-button-md rounded-button-md mb-200" />
        <Skeleton className="w-1800 h-300" />
        <div className="flex flex-col gap-100">
          <Skeleton className="w-full h-150 mb-200" />
          <Skeleton className="w-[50px] h-150" />
          <div className="flex flex-row gap-100">
            <Skeleton className="w-full h-100" />
            <Skeleton className="w-[20px] h-100" />
          </div>
        </div>
      </div>
      {new Array(totalOfSkeletons).fill('').map((_, index) => (
        <QuestCard.Skeleton key={`quest-list-skeleton-${index}`} />
      ))}
    </div>
  );
};

const QuestPage = () => {
  return (
    <div className="flex flex-col flex-1 min-w-0 w-full rounded-md bg-secondary min-h-0 h-full">
      <div className="flex flex-col flex-1 min-w-0 w-full py-700 px-200 md:px-800 gap-400 overflow-y-auto min-h-0 h-full xl:max-w-[882px] mx-auto">
        <Skeleton className="w-[300px] min-h-[48px]" />
        <div className="flex flex-row gap-200">
          <Skeleton className="rounded-xl min-w-reward-card-img-md h-[188px]" />
          <Skeleton className="rounded-xl min-w-reward-card-img-md h-[188px]" />
        </div>

        <div className="flex flex-col gap-200">
          <Skeleton className="w-full h-200" />
          <Skeleton className="w-full h-200" />
          <Skeleton className="w-1/3 h-200" />
        </div>

        <div className="flex flex-col gap-200">
          <Skeleton className="w-full h-[200px] rounded-xl" />
        </div>
      </div>
      <div className="flex flex-row border-t-separators py-200 mt-auto justify-end">
        <Skeleton className="w-full md:w-[145px] h-[48px] mx-200" />
      </div>
    </div>
  );
};

const Skeletons = {
  SectionView,
  ModuleList,
  QuestList,
  QuestPage,
};

export default Skeletons;
