import type { CSSProperties } from 'react';
import { useMemo } from 'react';
import { useTranslations } from 'next-intl';

import { Badge, ProgressBar } from '@zealy/design-system';
import { CheckCircleFilled, SnowflakeLine } from '@zealy/icons';

import { DEFAULT_THEME } from '#constants/questboard';
import { cn } from '#utils/utils';

import type { ModuleCardProps, ModuleHeaderProps } from './ModuleCard.types';
import { ModuleCardSkeleton } from './ModuleCard.skeleton';
import { styles } from './ModuleCard.styles';
import { calculateBrightness, hexToRgbString } from './ModuleCard.utils';

const moduleHeaderStyle = {
  backgroundImage: `linear-gradient(to bottom left, #00000000, var(--bg) 80%), var(--cover)`,
};

const fallbackHeaderStyle = {
  backgroundImage: 'var(--cover)',
};

const ModuleHeader = ({ actions, ...module }: ModuleHeaderProps) => {
  const t = useTranslations('questboard.badge.statuses');

  return (
    <div
      style={
        module.status === 'frozen'
          ? undefined
          : module.color
          ? moduleHeaderStyle
          : fallbackHeaderStyle
      }
      className={cn(
        styles.header({ status: module.status }),
        module.color && module.status !== 'locked' ? calculateBrightness(module.color) : '',
        module.color && module.image && !module.description ? 'min-h-[238px]' : '',
      )}
    >
      {actions}
      <div className="flex flex-col gap-150">
        <p className="module-card-quest-module-name text-primary">
          <span>{module.name}</span>
          {module.status === 'completed' && (
            <Badge className="inline-flex ml-150" icon={<CheckCircleFilled />}>
              {t('completed')}
            </Badge>
          )}

          {module.status === 'frozen' && (
            <Badge className="inline-flex ml-150" icon={<SnowflakeLine />} variant="info">
              {t('limit-reached')}
            </Badge>
          )}
        </p>

        {module.description && (
          <p className="body-paragraph-lg mb-100 text-secondary">{module.description}</p>
        )}

        {module.status !== 'completed' && module.quests > 0 && (
          <ProgressBar
            variant={module.variant}
            value={module.completed}
            max={module.quests}
            fillShadowBg="var(--bg)"
          />
        )}
      </div>
    </div>
  );
};

export const ModuleCard = ({
  className,
  children,
  fullHeight = false,
  ...props
}: ModuleCardProps) => {
  const t = useTranslations('questboard.badge.statuses');

  const shouldUseFallback = !props.image && !props.color;

  const fallbackTheme = shouldUseFallback
    ? DEFAULT_THEME[props.position % DEFAULT_THEME.length]
    : undefined;

  const coverUrl = props.image ? props.image : fallbackTheme?.coverUrl.src;

  const style = useMemo(
    () =>
      ({
        '--bg': props.color ? props.color : '',
        '--module-color': props.color
          ? hexToRgbString(props.color)
          : fallbackTheme?.color ?? '96,47,214',
        '--cover': `url(${coverUrl})`,
      } as CSSProperties),
    [props.color, coverUrl, fallbackTheme?.color],
  );

  return (
    <div
      className={cn(
        'flex flex-col box-border rounded-module-card-module-card relative',
        fullHeight ? '!border-0' : 'cursor-pointer',
        props.status === 'locked' ? 'grayscale' : '',
        className,
      )}
      style={style}
    >
      <ModuleHeader {...{ ...props, description: fullHeight ? props.description : undefined }} />
      {(props.status === 'open' || props.status === 'locked') && (
        <>
          <div
            className={cn(
              styles.content({ variant: props.status }),
              fullHeight
                ? 'max-h-full h-full pb-800 border-0 overflow-y-auto scroll-smooth scroll-pt-600'
                : 'border-module-card overflow-hidden',
            )}
          >
            {children}
          </div>
          <div className="bg-gradient-to-t bottom-0 left-0 right-0 absolute rounded-b-module-card-module-card from-module-card opacity-50 h-600" />
        </>
      )}
    </div>
  );
};

ModuleCard.Skeleton = ModuleCardSkeleton;
